body { margin: 0; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap');
*{font-family: 'Titillium Web', sans-serif;}

html { scroll-behavior: smooth; }
.animate-opacity{animation:opac 0.8s}@keyframes opac{from{opacity:0} to{opacity:1}}
.animate-bottom{position:relative;animation:animatebottom 0.8s}@keyframes animatebottom{from{bottom:-40px;opacity:0} to{bottom:0;opacity:1}}
.bg-blue{background-color: #5591b6}
.bg-river-bed{background-color: #5b6565}
.bg-panache{background-color: #F0F2EF}
.bg-energyyellow{background-color: #FCD64C!important;color: #000000!important;border-color: #FCD64C!important}
.text-energyyellow{color: #FCD64C;}
.navbar{background-color: #FCD64C}
.navbar-nav .nav-link{ color: #000000; }.navbar-nav .nav-link:hover, .navbar-nav .nav-link.active, .navbar-nav .show>.nav-.link{ color: rgb(30, 30, 30)!important; }
.nav-link{padding: 1rem!important; display: flex; }
.navbar-toggler{border-color:  rgb(30, 30, 30)!important;}
.navbar-toggler:focus{box-shadow: 0 0 0 0 rgba(0, 0, 0, 1)!important;}
.navbar-toggler-icon{background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e")!important}

main{background-color: black; color: #F0F2EF;}

.cover{
    color: #F0F2EF;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
    position: relative;
    min-height: 350px;
    background: url('https://www.trafficmanagementinmelbourne.com.au/bg01.webp') no-repeat scroll 0px 100% / cover transparent;
}

h1{font-size: 2.5rem;font-weight: bold;}
.pt6{padding-top: 4rem!important;}
